import React from 'react';

const MissionStatement = (props) => {
  const {isMobile} = props;
  return (
    <div style={styles.missionTextContainerStyle}>
      <p style={isMobile ? styles.missionMobileTextStyle : styles.missionTextStyle}>
        Reed Fiberglass has become well known to the Permian Basin and surrounding areas as a one-stop service company. 
        Incorporated in 1995, our fiberglass product line includes: chemical tanks, elliptical truck tanks, load line sumps, 
        secondary containment vessels (drip pans) and custom fabricated products. 
        In addition, we are a proud Norwesco poly tank dealer. Norwesco products include horizontal leg, vertical, 
        black water and applicator tanks made of high density polyethylene. We also have eight sizes of poly secondary 
        containment vessels.</p>
      <p style={isMobile ? {...styles.missionMobileTextStyle, marginTop: '2vmin' } : {...styles.missionTextStyle, marginTop: '2vmin' }}>
          We invite anyone needing fiberglass, polyethylene or steel products to come by our office at: 102 Reed Avenue or 
          give us a call at 1-800-990-TANK (8265) and visit with us about your next purchase. 
          We keep a large inventory of our standard products to provide faster delivery times at competitive prices. 
          Our friendly atmosphere will make you a repeat customer.
      </p>
    </div>
  )
};

export default MissionStatement;

const styles = {
  missionTextContainerStyle: {
    width: '80%',
    paddingTop: '2vmin'
  },
  missionTextStyle: {
    fontSize: '1.8vmin',
    margin: 0,
    color: 'maroon'
  },
  missionMobileTextContainerStyle: {
    width: '80%',
    paddingTop: '2vmin',
    paddingBottom: '4vmin'
  },
  missionMobileTextStyle: {
    fontSize: '4vmin',
    margin: 0,
    color: 'maroon'
  }
};