import React from 'react';
import topview from '../images/topview.png';
import map from '../images/map.png';
import EmailList from '../common/EmailList';

const ContactPage = (props) => {
  const {isMobile} = props;
  return (
    <div style={styles.containerStyle}>
      <h1 style={isMobile ? styles.headingMobileStyle : styles.headingStyle}>Contact Us</h1>
      <EmailList isMobile={isMobile} />
      <div style={isMobile ? styles.addressTextMobileContainerStyle : styles.addressTextContainerStyle}>
        <p style={isMobile ? styles.addressMobileTextStyle : styles.addressTextStyle}>102 Reed Avenue</p>
        <p style={isMobile ? styles.addressMobileTextStyle : styles.addressTextStyle}>&#183;</p>
        <p style={isMobile ? styles.addressMobileTextStyle : styles.addressTextStyle}>Odessa, TX</p>
        <p style={isMobile ? styles.addressMobileTextStyle : styles.addressTextStyle}>&#183;</p>
        <p style={isMobile ? styles.addressMobileTextStyle : styles.addressTextStyle}>79761</p>
      </div>
      
      <div style={isMobile ? styles.row1MobileContainerStyle : styles.row1ContainerStyle}>
        <img src={topview} alt='topview' style={isMobile ?
                                                  { ...styles.imageMobileStyle, width: '80%' } : 
                                                  { ...styles.imageStyle, width: '36%' }} />
        <img src={map} alt='map' style={isMobile ? 
                                          { ...styles.imageMobileStyle, width: '80%' } : 
                                          { ...styles.imageStyle, width: '32.75%' }} />
      </div>

    </div>
  )
}

export default ContactPage;

const styles = {
  containerStyle: {
    width: '98%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  headingStyle: {
    fontSize: '3vmin',
    color: 'maroon',
    margin: 0,
    marginTop: '1vmin'
  },
  addressTextContainerStyle: {
    width: '88%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-around', 
    paddingTop: '2vmin', 
    paddingBottom: '2vmin', 
    borderTop: '2px solid rgba(128,0,0,01)', 
    borderBottom: '2px solid rgba(128,0,0,01)'
  },
  addressTextStyle: {
    fontSize: '2vmin',
    color: 'maroon',
    margin: 0,
    fontWeight: 'bold'
  },
  row1ContainerStyle: {
    display: 'flex',
    alignItems: 'space-between', 
    justifyContent: 'space-around',
    padding: '4vmin',
    marginTop: '2vmin'
  },
  imageStyle: {
    height: 'auto', 
    border: '2px solid maroon', 
    borderRadius: 5
  },
  headingMobileStyle: {
    fontSize: '5vmin',
    color: 'maroon',
    margin: 0,
    marginTop: '1vmin'
  },
  addressTextMobileContainerStyle: {
    width: '88%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-around', 
    paddingTop: '2vmin', 
    paddingBottom: '2vmin', 
    borderTop: '2px solid rgba(128,0,0,01)', 
    borderBottom: '2px solid rgba(128,0,0,01)',
    marginTop: '1vmin'
  },
  addressMobileTextStyle: {
    fontSize: '4vmin',
    color: 'maroon',
    margin: 0,
    fontWeight: 'bold'
  },
  row1MobileContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'flex-start',
    marginTop: '2vmin'
  },
  imageMobileStyle: {
    height: 'auto', 
    border: '2px solid maroon', 
    borderRadius: 5,
    marginBottom: '2vmin'
  },
};