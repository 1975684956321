import React from 'react';
import image from '../../images/drumrackpack.png';
import image1 from '../../images/drp3.png';

const Container = (props) => {
  const {isMobile} = props;
  return (
    isMobile ?
    <div style={styles.containerMobileStyle1}> 
      <img src={image1} alt="product_img"  width='98%' height='auto' style={{ paddingRight: '4%' }} />
      <div style={styles.col1Style}>
        <p style={styles.textMobileStyle}>55/65 gallon poly drum on a 2' angled rack bolted to a 3' x 4' x 1' fiberglass secondary containment vessel</p>
        <p style={styles.textMobileStyle}>55/65 gallon poly drum on a 2' angled rack not bolted or attached to a 3' x 4' x1' fiberglass secondary containment vessel, in both horizontal and vertical arrangements</p>
      </div>
      <img src={image} alt="product_img"  width='80%' height='auto' style={{ margin: 0 }} />
    </div> :

    <div style={styles.containerStyle1}> 
      <img src={image1} alt="product_img"  width='75%' height='auto' style={{ marginLeft: '5%' }} />
      <div style={styles.containerStyle2}> 
        <div style={styles.col1Style}>
          <p style={styles.textStyle}>55/65 gallon poly drum on a 2' angled rack bolted to a 3' x 4' x 1' fiberglass secondary containment vessel</p>
          <p style={styles.textStyle}>55/65 gallon poly drum on a 2' angled rack not bolted or attached to a 3' x 4' x1' fiberglass secondary containment vessel, in both horizontal and vertical arrangements</p>
        </div>
        <div style={styles.col2Style}>
          <img src={image} alt="product_img"  width='50%' height='auto' />
        </div>
      </div>
    </div>
  )
};

export default Container;

const styles = {
  containerStyle1: {
    width: '100%',
    height: '100%'
  },
  containerStyle2: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col1Style: {
    width: '80%',
    height: '100%',
    paddingRight: '5%',
    paddingLeft: '5%',
  },
  col2Style: {
    width: '80%',
    height: '100%',
    display: 'flex',
    flexDirection :'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  textStyle: {
    fontSize: '2vmin',
    color: 'rgba(0,0,0,0.8)'
  },
  containerMobileStyle1: {
    width: '100%',
    height: 'auto',
    marginBottom: '10vmin'
  },
  textMobileStyle: {
    fontSize: '4.5vmin',
    color: 'rgba(0,0,0,0.8)'
  }
};