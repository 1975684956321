import React from 'react';
import image1 from '../../images/20galsump.png';
import image2 from '../../images/2550galsump.png';
import image3 from '../../images/80galsump.png';
import image4 from '../../images/sump1.png';

const Container = (props) => {
  const {isMobile} = props;

  return (
    isMobile ? 
      <div style={styles.containerMobileStyle1}> 
        <img src={image4} alt="product_img" width='95%' height='auto' />
          <div style={styles.col2MobileStyle}>
            <div style={{ width: '33%'}}>
              <img src={image1} alt="product_img" width='100%' height='auto' />
              <p style={styles.textMobileStyle}>20 Gallon Sump</p>
            </div>
            <div style={{ width: '33%'}}>
              <img src={image2} alt="product_img" width='100%' height='auto' />
              <p style={styles.textMobileStyle}>25 - 50 Gallon Sump</p>
            </div>
            <div style={{ width: '33%'}}>
              <img src={image3} alt="product_img" width='100%' height='auto' />
              <p style={styles.textMobileStyle}>80 Gallon Sump</p>
            </div>
          </div>
      </div> :

      <div style={styles.containerStyle1}> 
        <div style={styles.containerStyle2}>
          <div style={styles.col1Style}>
            <img src={image4} alt="product_img" width='80%' height='auto' />
          </div>
          <div style={styles.col2Style}>
            <div style={{ width: '33%'}}>
              <img src={image1} alt="product_img" width='100%' height='auto' />
              <p style={styles.textStyle}>20 Gallon Sump</p>
            </div>
            <div style={{ width: '33%'}}>
              <img src={image2} alt="product_img" width='100%' height='auto' />
              <p style={styles.textStyle}>25 - 50 Gallon Sump</p>
            </div>
            <div style={{ width: '33%'}}>
              <img src={image3} alt="product_img" width='100%' height='auto' />
              <p style={styles.textStyle}>80 Gallon Sump</p>
            </div>
          </div>
        </div>
      </div>
  )
};

export default Container;

const styles = {
  containerStyle1: {
    width: '100%',
    height: '100%'
  },
  containerStyle2: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col1Style: {
    width: '60%',
    height: '100%',
    paddingLeft: '5%',
  },
  col2Style: {
    width: '45%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: '5%'
  },
  textStyle: {
    fontSize: '1.6vmin',
    color: 'maroon'
  },
  containerMobileStyle1: {
    width: '100%',
    height: 'auto',
    marginBottom: '10vmin'
  },
  textMobileStyle: {
    fontSize: '4.5vmin',
    color: 'rgba(0,0,0,0.8)'
  },
  col2MobileStyle: {
    width: '95%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: '5%'
  },
};
