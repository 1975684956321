import React from 'react';
import image from '../../images/environmentalcontainment.png';
import image1 from '../../images/envcontainment1.png';
import image2 from '../../images/envcontainment2.png';
import image3 from '../../images/ec1.png';

const Container = (props) => {
  const {isMobile} = props;

  return (
    isMobile ?
    <div style={styles.containerStyle1}> 
      <img src={image3} alt="product_img" width='98%' height='auto' style={{ marginLeft: '1%' }} />
      <img src={image1} alt="product_img" width='98%' height='auto' />
      <div style={styles.col2MobileStyle}>
        <img src={image} alt="product_img" width='90%' height='auto' />
        <img src={image2} alt="product_img" width='90%' height='auto' />
      </div>
    </div> :

    <div style={styles.containerStyle1}> 
      <img src={image3} alt="product_img" width='75%' height='auto' style={{ marginLeft: '5%' }} />
      <div style={styles.containerStyle2}>
        <div style={styles.col1Style}>
        <img src={image1} alt="product_img" width='85%' height='auto' />
        </div>
        <div style={styles.col2Style}>
          <img src={image} alt="product_img" width='50%' height='auto' />
          <img src={image2} alt="product_img" width='30%' height='auto' />
        </div>
      </div>
    </div>
  )
};

export default Container;

const styles = {
  containerStyle1: {
    width: '100%',
    height: '100%'
  },
  containerStyle2: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col1Style: {
    width: '50%',
    height: '100%',
    paddingRight: '5%',
    paddingLeft: '5%',
  },
  col2Style: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection :'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  textStyle: {
    fontSize: '1.8vmin'
  },
  containerMobileStyle1: {
    width: '100%',
    height: 'auto',
    marginBottom: '10vmin'
  },
  textMobileStyle: {
    fontSize: '4.5vmin',
    color: 'rgba(0,0,0,0.8)'
  },
  col2MobileStyle: {
    width: '80%',
    height: 'auto',
    display: 'flex',
    flexDirection :'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '-5vmin'
  },
};