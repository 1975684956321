import React from 'react';

const EmailList = (props) => {
  const {isMobile} = props;
  return (
    <div style={isMobile ? styles.containerMobileStyle : styles.containerStyle}> 
      <p style={isMobile ? styles.textMobileStyle : styles.textStyle}>Company:  
          <a href="mailto:jessica@reedfiberglass.com" style={isMobile ? styles.linkMobileStyle : styles.linkStyle}>
            jessica@reedfiberglass.com
          </a>
        </p>
        <p style={isMobile ? styles.textMobileStyle : styles.textStyle}>Matt:  
          <a href="mailto:matt@reedfiberglass.com" style={isMobile ? styles.linkMobileStyle : styles.linkStyle}>
            matt@reedfiberglass.com
          </a>
        </p>
    </div>
  )
}

export default EmailList;

const styles = {
  containerStyle: {
   width: '86%',
   marginBottom: '3vmin'
  },
  textStyle: {
    fontSize: '1.8vmin',
    margin: 0,
    marginLeft: '2vmin',
    marginTop: '.75vmin',
    fontWeight: 'bold'
  },
  linkStyle: {
    fontSize: '1.6vmin',
    margin: 0,
    color: 'maroon',
    textDecoration: 'none',
    marginLeft: '.5vmin'
  },
  containerMobileStyle: {
    width: '86%',
    marginBottom: '3vmin',
    marginTop: '2vmin'
   },
  textMobileStyle: {
    fontSize: '4vmin',
    margin: 0,
    marginLeft: '2vmin',
    marginTop: '3vmin',
    fontWeight: 'bold'
  },
  linkMobileStyle: {
    fontSize: '4vmin',
    margin: 0,
    color: 'maroon',
    textDecoration: 'none',
    marginLeft: '.5vmin'
  },
}