import React from 'react';
import ProductList from '../common/ProductList';

const ProductPage = (props) => { 

  const {productList, setProduct, isMobile} = props;

  return (
    <div style={styles.containerStyle}>
      <h1 style={isMobile ? styles.headingMobileStyle : styles.headingStyle}>Products</h1>
      <div style={{ ...styles.rowContainerStyle, flexDirection: 'column' }}>
        <p style={isMobile ? 
                    { ...styles.textMobileStyle, fontWeight: 'bold', textDecoration: 'underline' } :
                    { ...styles.textStyle, fontWeight: 'bold', textDecoration: 'underline' }}>
          Custom Fabrication
        </p>
        <p style={isMobile ? 
                    { ...styles.textMobileStyle, marginTop: '1vmin', marginBottom: '1vmin' } :
                    { ...styles.textStyle, marginTop: '1vmin', marginBottom: '1vmin' }}>
          Many of our customers require products made to their specifications. Just give us a call and we will custom fabricate to meet your needs. All of our products may be "dressed out" to customer specifications.
        </p>
        <p style={isMobile ? 
                    { ...styles.textMobileStyle, fontWeight: 'bold', textDecoration: 'underline' } :
                    { ...styles.textStyle, fontWeight: 'bold', textDecoration: 'underline' }}>
          Warranty
        </p>
        <p style={isMobile ? 
                    { ...styles.textMobileStyle, marginTop: '1vmin' } :
                    { ...styles.textStyle, marginTop: '1vmin' }}>
          Reed Fiberglass will warranty our fiberglass products for 1 year. (This warranty is subject to research of the material or product placed at fault for damage.)
        </p>
      </div>
      <p style={isMobile ? 
                  { ...styles.textMobileStyle, marginTop: '-2vmin' } : 
                  { ...styles.textStyle, marginTop: '-2vmin' }}>
        Please click on a product name below for more information
      </p>
      <div style={isMobile ? styles.rowMobileContainerStyle : styles.rowContainerStyle}>
        <ProductList  isMobile={isMobile} 
                      name={'Fiberglass'} 
                      list={productList && productList.length > 0 ? 
                              productList.filter(i => i.type === 'fiberglass') : []} 
                      setProduct={setProduct} />
        <ProductList  isMobile={isMobile} 
                      name ={'Steel'} 
                      list={productList && productList.length > 0 ? 
                              productList.filter(i => i.type === 'steel') : []} 
                      setProduct={setProduct} />
        <ProductList  isMobile={isMobile} 
                      name={'Poly'} 
                      list={productList && productList.length > 0 ? 
                              productList.filter(i => i.type === 'poly') : []} 
                      setProduct={setProduct} />
      </div>
    </div>
  )
}

export default ProductPage;

const styles = {
  containerStyle: {
    width: '98%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  headingStyle: {
    fontSize: '3vmin',
    color: 'maroon',
    margin: 0,
    marginTop: '1vmin'
  },
  rowContainerStyle: {
    display: 'flex',
    alignItems: 'space-between', 
    justifyContent: 'space-around',
    padding: '4vmin',
    width: '90%'
  },
  textStyle: {
    fontSize: '1.8vmin',
    color: 'maroon',
    margin: 0
  },
  containerMobileStyle: {
    width: '98%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  headingMobileStyle: {
    fontSize: '5vmin',
    color: 'maroon',
    margin: 0,
    marginTop: '1vmin'
  },
  textMobileStyle: {
    fontSize: '3vmin',
    color: 'maroon',
    margin: 0
  },
  rowMobileContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    padding: '4vmin',
    width: '90%'
  },
}