import React from 'react';
import ImageCarousel from '../common/ImageCarousel';
import PromiseStatement from '../common/PromiseStatement.js';
import MissionStatement from '../common/MissionStatement.js';

const HomePage = (props) => {
  const {isMobile} = props;
  return (
    isMobile ? 
    <div style={styles.containerMobileStyle}>
      <ImageCarousel isMobile={true} />
      {/* <div style={styles.row1MobileContainerStyle}> */}
      <PromiseStatement isMobile={true} />
      <MissionStatement isMobile={true} />
      {/* </div> */}
    </div> :
    
    <div style={styles.containerStyle}>
      <div style={styles.row1ContainerStyle}>
        <ImageCarousel />
        <PromiseStatement />
      </div>
      <MissionStatement />
    </div>
  )
}

export default HomePage;

const styles = {
  containerStyle: {
    width: '98%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  row1ContainerStyle: {
    display: 'flex',
    alignItems: 'flex-start', 
    justifyContent: 'space-between',
    borderBottom: '2px solid rgba(128,0,0,01)',
    padding: '4vmin'
  },
  containerMobileStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '2vmin'
  },
  row1MobileContainerStyle: {
    display: 'flex',
    alignItems: 'flex-start', 
    justifyContent: 'space-between',
    borderTop: '2px solid rgba(128,0,0,01)',
    paddingTop: '2vmin',
    marginTop: '2vmin',
  }
};