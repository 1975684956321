import React from 'react';

const currentYear = new Date().getFullYear();

const Footer = (props) => {
  const {isMobile} = props;
  return (
    <div style={styles.footerContainer}>
      <p style={isMobile ? styles.copyrightMobileText : styles.copyrightText}>{`© ${currentYear} All Rights Reserved`}</p>
      <p style={isMobile ? styles.mobileText : styles.text}>Reed Fiberglass 102 Reed Avenue Odessa Tx 79761</p>
    </div>
  );
}

export default Footer;

const styles = {
  footerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    position: 'relative',
    borderTop: '2px solid rgba(0,0,0,0.2)',
    textAlign: 'center'
  },
  copyrightText: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '1.3vmin',
    textAlign: 'left',
    position: 'absolute',
    bottom: '1vmin',
    left: '5%'
  },
  text: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '2vmin',
  },
  copyrightMobileText: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '2vmin',
    textAlign: 'left',
    position: 'absolute',
    bottom: '.5vmin',
    left: '5%',
    margin: 0
  },
  mobileText: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '3vmin'
  }
};