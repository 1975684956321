import React from 'react';

const ProductList = (props) => {
  
  const {name, list, setProduct, isMobile} = props;

  return (
    <div style={isMobile ? styles.containerMobileStyle : styles.containerStyle}>
      <div style={isMobile ? styles.headerMobileStyle : styles.headerStyle}>
        <p style={{ margin: 0 }}>{name}</p>
      </div>
      {list.map((product, index) => (
        <p key={index} style={isMobile ? styles.textMobileStyle : styles.textStyle} onClick={() => setProduct(product.name.toLowerCase().split(' ').join('-'))}>&#183; {product.name}</p>
        ))
      }
    </div>
  )
};

export default ProductList;

const styles = {
  containerStyle: {
    border: '2px solid maroon',
    borderRadius: 5,
    width: "28%"
  },
  headerStyle: {
    fontSize: '1.8vmin',
    backgroundColor: 'maroon',
    textAlign: 'center',
    paddingRight: '1%',
    paddingLeft: '1%',
    paddingTop:'.5%',
    paddingBottom: '1%',
    color: 'white',
    marginBottom: '2vmin'
  },
  textStyle: {
    fontSize: '2vmin',
    margin: 0,
    marginBottom: '2vmin',
    cursor: 'pointer',
    paddingRight: '2%',
    paddingLeft: '2%'
  },
  containerMobileStyle: {
    border: '2px solid maroon',
    borderRadius: 5,
    width: "90%",
    marginBottom: '2vmin'
  },
  headerMobileStyle: {
    fontSize: '5vmin',
    backgroundColor: 'maroon',
    textAlign: 'center',
    paddingRight: '1%',
    paddingLeft: '1%',
    paddingTop:'.5%',
    paddingBottom: '1%',
    color: 'white',
    marginBottom: '2vmin'
  },
  textMobileStyle: {
    fontSize: '5vmin',
    margin: 0,
    cursor: 'pointer',
    paddingRight: '2%',
    paddingLeft: '2%',
    marginBottom: '2vmin'
  },
};
