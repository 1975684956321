import React from 'react';
import logo from '../images/ReedLogo.png'
import '../App.css';

const DataLeft = (props) => {
  const isMobile = props.isMobile;
  return (
    <div>
      <p style={isMobile ? 
                  {...styles.leftMobileTextStyle, marginBottom: '1.5vmin', fontWeight: 'bold' } : 
                  {...styles.leftTextStyle, marginBottom: '1.5vmin', fontWeight: 'bold' }}>
        Contact Us Today!
      </p>
      <p style={isMobile ? 
                  styles.leftMobileTextStyle :
                  styles.leftTextStyle}>
        432-332-TANK
      </p>
      <p style={isMobile ? 
                  styles.leftMobileTextStyle :
                  styles.leftTextStyle}>
        800-900-TANK
      </p>
    </div>
  )
};

const DataCenter = (props) => {
  const isMobile = props.isMobile;
  return (
    <div>
      <q style={isMobile ? styles.sloganMobileTextStyle : styles.sloganTextStyle}>
        Our goal is to have what you need when you need it!
      </q>
    </div>
  )
};

const DataRight = (props) => {
  const {currentPage, setPage, isMobile} = props.data;

  return (
      <img src={logo} 
           alt="logo" 
           style={isMobile ? 
                   styles.logoMobileStyle : 
                   styles.logoStyle} 
           onClick={() => currentPage !== 'home' ? setPage('home') : null } />
  )
};

const Header = (props) => {  
  const {isMobile} = props;
  return (
    isMobile ? 
      <div style={styles.containerMobileStyle} className="header_fill">
        <div style={styles.mobileRowStyle}>
          <DataLeft isMobile={true}/>
          <DataRight data={props} />
        </div>
        <DataCenter isMobile={true} />
      </div> :

      <div style={styles.containerStyle} className="header_fill">
        <DataLeft />
        <DataCenter />
        <DataRight data={props} />
      </div> 
  );
};

export default Header;

const styles = {
  containerStyle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: '2vmin'
  },
  leftTextStyle: {
    fontSize: '2vmin',
    margin: '.5vmin',
    color: 'white'
  },
  sloganTextStyle: {
    fontSize: '2.75vmin',
    margin: 0,
    color: 'white',
    fontStyle: 'italic',
    fontWeight: 'bold'
  },
  logoStyle: {
    height: '7vmin',
    width: 'auto',
    objectFit: 'contain',
    margin: 0,
    cursor: 'pointer'
  },
  containerMobileStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: '2vmin'
  },
  mobileRowStyle: {
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-around',
    marginBottom: '1vmin'
  },
  leftMobileTextStyle: {
    fontSize: '4vmin',
    margin: '.5vmin',
    color: 'white'
  },
  sloganMobileTextStyle: {
    fontSize: '3.5vmin',
    margin: 0,
    color: 'white',
    fontStyle: 'italic',
    fontWeight: 'bold',
    width: '100%',
  },
  logoMobileStyle: {
    height: '15vmin',
    width: 'auto',
    objectFit: 'contain',
    margin: 0,
    cursor: 'pointer'
  },
};