import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img1 from '../images/carousel1.png';
import img2 from '../images/carousel2.png';
import img3 from '../images/carousel3.png';

const ImageCarousel = (props) => {
  const {isMobile} = props;
  return (
    <div style={{ width: isMobile ? '100%' : '75%' }}>
      <Carousel 
        autoPlay 
        infiniteLoop 
        stopOnHover 
        showStatus={false} 
        showThumbs={false} 
        showArrows={false} 
        interval={6000} 
        transitionTime={2} 
        width={'100%'}>
          <div>
              <img src={img1} alt='product_img_1' />
          </div>
          <div >
              <img src={img2} alt='product_img_2' />
          </div>
          <div>
              <img src={img3} alt='product_img_3' />
          </div>
      </Carousel>
    </div>
  )
}

export default ImageCarousel;