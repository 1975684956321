import React, {useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import './App.css';
import Header from './common/Header';
import Nav from './common/Nav';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import ProductDetailPage from './pages/ProductDetailPage';
import ContactPage from './pages/ContactPage';
import Footer from './common/Footer';

const App = () => {

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('page') ? sessionStorage.getItem('page') : 'home');
  const [selectedProduct, setSelectedProduct] = useState(sessionStorage.getItem('product') ? sessionStorage.getItem('product') : null);

  const setPage = (page) => {
    sessionStorage.setItem('page', page)
    sessionStorage.removeItem('product');
    setCurrentPage(page);
    setSelectedProduct(null);
  };

  const setProduct = (product) => {
    sessionStorage.setItem('product', product);
    sessionStorage.setItem('page', 'productdetails')
    setCurrentPage('productdetails');
    setSelectedProduct(product);
  };

  const backToAllProducts = () => {
    sessionStorage.removeItem('product');
    sessionStorage.setItem('page', 'products');
    setCurrentPage('products');
    setSelectedProduct(null);
  };

  const productList = [
    {type: 'fiberglass', name: 'Drum Rack Pack'},
    {type: 'fiberglass', name: 'Elliptical Truck Tanks'},
    {type: 'fiberglass', name: 'Environmental Containment'},
    {type: 'fiberglass', name: 'Fiberglass Chemical Tanks'},
    {type: 'fiberglass', name: 'Load Line Sumps'},
    {type: 'fiberglass', name: 'Secondary Containment Vessels'},
    {type: 'fiberglass', name: 'Open Top Tanks'},
    {type: 'poly', name: 'Horizontal Leg Tanks'},
    {type: 'poly', name: 'Poly Secondary Containment'},
    {type: 'poly', name: 'Potable Water Tanks'},
    {type: 'poly', name: 'Vertical Storage Tanks'},
    {type: 'steel', name: 'Steel Tanks'}
  ];

  return (
    <div style={styles.containerStyle}>
      <Header currentPage={currentPage} 
              setPage={setPage}
              isMobile={isMobile} />
      <Nav currentPage={currentPage} 
           setPage={setPage}
           isMobile={isMobile} />

      <div style={isMobile ? styles.pageMobileContainerStyle : styles.pageContainerStyle}>
        { currentPage === 'home' ? 
            <HomePage isMobile={isMobile} /> : 
          currentPage === 'products' ? 
            <ProductPage productList={productList} 
                         setProduct={setProduct}
                         isMobile={isMobile} /> :
          currentPage === 'contact' ? 
            <ContactPage isMobile={isMobile} /> :
          currentPage === 'productdetails' ?
            <ProductDetailPage productList={productList} 
                               selectedProduct={selectedProduct}
                               isMobile={isMobile} /> :
            <p>404 page not found</p>
        }
        { selectedProduct && 
            <button style={isMobile ? styles.backButtonMobileStyle : styles.backButtonStyle} 
                    onClick={backToAllProducts}>
              &lArr; all products
            </button>
        }
      </div>

      {!isMobile && 
          <Footer isMobile={isMobile} />
      }
    </div>
  );
}

export default App;

const styles = {
  containerStyle: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'fixed',
    top: 0, left: 0,
    maxHeight: '100vwh',
    maxWidth: '100vw',
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  pageContainerStyle: {
    display: 'flex', 
    width: '100%', 
    flex: 1, 
    flexDirection: 'column', 
    alignItems: 'center',
    justifyContent: 'flex-start', 
    backgroundColor: 'white',
    borderTop: '2px solid rgba(128,0,0,01)',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative'
  },
  backButtonStyle: {
    position: 'absolute', 
    top: '3vmin', 
    left: '5%',
    border: 'none',
    backgroundColor: 'maroon',
    color: 'white',
    borderRadius: 5, 
    fontWeight: 'bold',
    fontSize: '1.4vmin',
    padding: '.5vmin',
    paddingRight: '1vmin', 
    paddingLeft: '1vmin',
    cursor: 'pointer'
  },
  pageMobileContainerStyle: {
    display: 'flex', 
    width: '100%', 
    marginBottom: '40vmin',
    flexDirection: 'column', 
    alignItems: 'center',
    justifyContent: 'flex-start', 
    backgroundColor: 'white',
    borderTop: '2px solid rgba(128,0,0,01)',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative'
  },
  backButtonMobileStyle: {
    position: 'absolute', 
    top: '3vmin', 
    left: '5%',
    border: 'none',
    backgroundColor: 'maroon',
    color: 'white',
    borderRadius: 5, 
    fontWeight: 'bold',
    fontSize: '4vmin',
    padding: '.5vmin',
    paddingRight: '1vmin', 
    paddingLeft: '1vmin',
    cursor: 'pointer'
  },
};