import React from 'react';
import image from '../../images/polysecondary.png';
import image1 from '../../images/polysec1.png';
import image2 from '../../images/polysec2.png';
import image3 from '../../images/secondarycontainment2.png';

const Container = (props) => {
  const {isMobile} = props;

  return (
    isMobile ?
      <div style={styles.containerMobileStyle1}> 
        <img src={image1} alt="product_img" width='95%' height='auto' style={{ textAlign: 'center', marginLeft: '1%' }} />
        <img src={image2} alt="product_img" width='98%' height='auto' />
        <div style={styles.col2MobileStyle}>
          <img src={image} alt="product_img" width='75%' height='auto' />
          <img src={image3} alt="product_img" width='65%' height='auto' />
        </div>
      </div> :
      
      <div style={styles.containerStyle1}> 
        <img src={image1} alt="product_img" width='70%' height='auto' style={{ textAlign: 'center', marginLeft: '5%' }} />
        <div style={styles.containerStyle2}>
          <div style={styles.col1Style}>
            <img src={image2} alt="product_img" width='50%' height='auto' />
          </div>
          <div style={styles.col2Style}>
            <img src={image} alt="product_img" width='65%' height='auto' />
            <img src={image3} alt="product_img" width='45%' height='auto' />
          </div>

        </div>
      </div>
  )
};

export default Container;

const styles = {
  containerStyle1: {
    width: '100%',
    height: '100%'
  },
  containerStyle2: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col1Style: {
    width: '60%',
    height: '100%',
    paddingRight: '5%',
    paddingLeft: '5%',
  },
  col2Style: {
    width: '40%',
    height: '100%',
    display: 'flex',
    flexDirection :'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  textStyle: {
    fontSize: '1.8vmin'
  },
  containerMobileStyle1: {
    width: '100%',
    height: 'auto',
    marginBottom: '10vmin'
  },
  textMobileStyle: {
    fontSize: '4.5vmin',
    color: 'rgba(0,0,0,0.8)'
  },
  col2MobileStyle: {
    width: '95%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingRight: '5%'
  },
};