import React from 'react';

const PromiseStatement = (props) => {
  const {isMobile} = props;
  return (
    <div style={isMobile ? styles.statementMobileContainerStyle : styles.statementContainerStyle}>
      <p style={isMobile ? 
                  { ...styles.statementMobileTextStyle, marginBottom: '1vmin', fontWeight: 'bold' } :
                  { ...styles.statementTextStyle, marginBottom: '1vmin', fontWeight: 'bold' }}>
        Our Promise To You
      </p>
      <hr style={{ width: '80%' }}/>
      <p style={isMobile ? styles.statementMobileTextStyle : styles.statementTextStyle}>Quality Products</p>
      <p style={isMobile ? styles.statementMobileTextStyle : styles.statementTextStyle}>Customer Satisfaction</p>
      <p style={isMobile ? styles.statementMobileTextStyle : styles.statementTextStyle}>Competitive Pricing</p>
      <p style={isMobile ? styles.statementMobileTextStyle : styles.statementTextStyle}>Fast Delivery</p>
      <p style={isMobile ? styles.statementMobileTextStyle : styles.statementTextStyle}>Friendly Service</p>
    </div>
  )
}

export default PromiseStatement;

const styles = {
  statementContainerStyle: {
    textAlign: 'center',
    width: '30%',
    margin: 'auto'
  },
  statementTextStyle: {
    fontSize: '2vmin',
    margin: 0,
    color: 'maroon',
    marginBottom: '.5vmin'
  },
  statementMobileContainerStyle: {
    textAlign: 'center',
    width: '90%',
    margin: 'auto'
  },
  statementMobileTextStyle: {
    fontSize: '3vmin',
    margin: 0,
    color: 'maroon',
    marginBottom: '.5vmin'
  }
}