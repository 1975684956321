import React from 'react';
import DrumRackPack from './products/DrumRackPack';
import EllipticalTruckTanks from './products/EllipticalTruckTanks';
import EnvironmentalContainment from './products/EnvironmentalContainment';
import FiberglassChemicalTanks from './products/FiberglassChemicalTanks';
import HorizontalLegTanks from './products/HorizontalLegTanks';
import LoadLineSumps from './products/LoadLineSumps';
import OpenTopTanks from './products/OpenTopTanks';
import PolySecondaryContainment from './products/PolySecondaryContainment';
import PotableWaterTanks from './products/PotableWaterTanks';
import SecondaryContainmentVessels from './products/SecondaryContainmentVessels';
import SteelTanks from './products/SteelTanks';
import VerticalStorageTanks from './products/VerticalStorageTanks';

const ProductDetailPage = (props) => {
  
  const {selectedProduct, isMobile} = props;

  const renderProductPage = (isMobile) => {
    switch(selectedProduct) {
      case 'drum-rack-pack':
        return <DrumRackPack isMobile={isMobile} />;
      case 'elliptical-truck-tanks':
        return <EllipticalTruckTanks isMobile={isMobile} />;
      case 'environmental-containment':
        return <EnvironmentalContainment isMobile={isMobile} />;
      case 'fiberglass-chemical-tanks':
        return <FiberglassChemicalTanks isMobile={isMobile} />;
      case 'horizontal-leg-tanks':
        return <HorizontalLegTanks isMobile={isMobile} />;
      case 'load-line-sumps':
        return <LoadLineSumps isMobile={isMobile} />;
      case 'open-top-tanks':
        return <OpenTopTanks isMobile={isMobile} />;
      case 'poly-secondary-containment':
        return <PolySecondaryContainment isMobile={isMobile} />;
      case 'potable-water-tanks':
        return <PotableWaterTanks isMobile={isMobile} />;
      case 'secondary-containment-vessels':
        return <SecondaryContainmentVessels isMobile={isMobile} />;
      case 'steel-tanks':
        return <SteelTanks isMobile={isMobile} />;
      case 'vertical-storage-tanks':
        return <VerticalStorageTanks isMobile={isMobile} />;
      default:
        return <p>404 page not found</p>;
    };
  };

  return (
    <div style={isMobile ? styles.containerMobileStyle : styles.containerStyle}>
      <h1 style={isMobile ? styles.headingMobileStyle : styles.headingStyle}>{selectedProduct.split('-').join(' ').toUpperCase()}</h1>
      {renderProductPage(isMobile)}
    </div>
  )
};

export default ProductDetailPage;

const styles = {
  containerStyle: {
    width: '80%',
    marginLeft: '10%'
  },
  headingStyle: {
    marginLeft: '5%',
    fontSize: '3.5vmin'
  },
  containerMobileStyle: {
    width: '95%',
    marginLeft: '2.5%',
    paddingTop: '6vmin'
  },
  headingMobileStyle: {
    marginLeft: '2.5%',
    fontSize: '6vmin'
  },
};