import React from 'react';

const linklist = [ 'Home', 'Products', 'Contact'];

const Links = (props) => {

  const {currentPage,setPage,isMobile} = props.data;

  return (
    <div style={isMobile ? styles.linkMobileContainer : styles.linkContainer}>
      {linklist.map((link, index) => (
              <p  key={link}
                  style={isMobile ? { ...styles.linkMobileText, 
                                      backgroundColor: currentPage === link.toLowerCase() ? 'maroon' : 'rgba(0,0,0,0.5)' } :
                                    { ...styles.linkText, 
                                      backgroundColor: currentPage === link.toLowerCase() ? 'maroon' : 'rgba(0,0,0,0.5)' }} 
                 onClick={() => setPage(link.toLowerCase())}>
                {link}
              </p>
          ))
      }
      </div>   
  )
}


const Nav = (props) => {
  const {isMobile} = props;
  return (
    <div style={isMobile ? styles.navMobileContainer : styles.navContainer} className="header_fill">
      <Links data={props} />
    </div>
  );
}

export default Nav;

const styles = {
  navContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center'
  },
  linkContainer: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-around',
    width: '40%',
    paddingTop: '.5vmin',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    paddingBottom: '.5vmin',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  linkText: {
    textDecoration: 'none', 
    fontWeight: 'bold',
    fontSize: '1.6vmin',
    color: 'white',
    cursor: 'pointer',
    padding: '.5vmin',
    paddingRight: '2vmin',
    paddingLeft: '2vmin',
    borderRadius: 5,
    margin: 0
  },
  navMobileContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    paddingTop: '2vmin'
  },
  linkMobileContainer: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-around',
    width: '100%',
    paddingTop: '.5vmin',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    paddingBottom: '.5vmin',
    backgroundColor: 'rgba(255,255,255,0.5)',
    textAlign: 'center'
  },
  linkMobileText: {
    textDecoration: 'none', 
    fontWeight: 'bold',
    fontSize: '4.5vmin',
    color: 'white',
    cursor: 'pointer',
    padding: '1vmin',
    paddingRight: '2vmin',
    paddingLeft: '2vmin',
    borderRadius: 5,
    margin: 0,
    width: '28%'
  },
};